import logo from './logo.svg';
import restock_img from "./restock_warning.png";
import './App.css';

function App() {
  return (
    <div className="App">
      hello
      <div style={{border:"1px solid",width:432}}>
      <div className="text">You will be charged a fee if you wish to return any product.</div>
      <div style={{marginTop:16, marginBottom:16}}>
        <img  src={restock_img} style={{width:432}} />
      </div>
      <div className="subHeader">
         Why will I be charged?
      </div>
      <div  className="text"> Since you have an unusually high number of returns in your past orders, we are now charging a convenience fee on your returns.</div>
      <div style={{ border: "1px solid #D5D5D5", marginTop: 14, marginBottom: 16 }}/>
      <div className="subHeader">How can I avoid this in future?</div>
      <div className="text">Reduce your returns by checking all product details, size guides, and customer recommendations before placing an order.</div>
      </div>
    </div>
  );
}

export default App;
